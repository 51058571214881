.faq__section__one {
  background: url(../../assets/joshua-hoehne-iggWDxHTAUQ-unsplash.jpg) center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5% 0;
  padding-top: 30vh;
  color: white;
  flex-direction: column;
  min-height: 50vh;
}
.faq__section__one > h3 {
  font-size: 20px;
  font-weight: 200;
}
.faq__section__one > h1 {
  font-size: 40px;
}
.faq__section__two {
  padding: 2.5% 25%;
}
.faq__section__two > p {
  font-size: 15px;
  line-height: 35px;
  font-weight: 200;
  margin: 30px 0;
}
.faq__section__two > h1 {
  font-size: 25px;
  font-weight: 200;
  margin: 30px 0;
  color: #ffcc00;
}
.faq__section__two__faq {
  margin: 10px 0;
  width: 100%;
  padding: 10px 0;
  border-top: 0.5px solid #cccccc;
}
.faq__section__two__faq__ques {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
.faq__section__two__faq__ques > p {
  font-size: 17spx;
}
.faq__section__two__faq__ques:hover {
  color: #ffcc00;
}
.faq__section__two__faq__icon {
  font-size: 18px;
  margin-left: auto;
  transition: 0.5s ease-in-out;
}
.faq__section__two__faq__icon.clicked {
  transform: rotate(180deg);
}
.faq__section__two__faq__ans {
  transition: 0.5s ease-in-out;
}
.faq__section__two__faq__ans.clicked {
  display: none;
}
.faq__section__two__faq__ans p {
  font-size: 13px;
  font-weight: 200;
  line-height: 25px;
  width: 100%;
  color: #222222;
}

@media all and (max-width: 768px) {
  .faq__section__one {
    justify-content: center;
    align-items: start;
    padding: 5% 5%;
    padding-top: 30vh;
  }
  .faq__section__one > h1 {
    font-size: 30px;
  }
  .ls__main__section__three__faq__ans p {
    width: 100%;
  }
  .faq__section__two {
    padding: 5% 5%;
  }
}
