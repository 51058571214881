.modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modal.disable {
  display: none;
}
.modal__content {
  min-width: 45%;
  min-height: 40vh;
  background: #fff;
  color: black;
  padding: 20px 20px;
}
.modal__content__span {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
}
.modal__content__close {
  margin-left: auto;
  font-size: 25px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.modal__content__close:hover {
  color: crimson;
}

@media all and (max-width: 768px) {
  .modal__content {
    height: 100vh;
    width: 95%;
    padding: 5% 5%;
    overflow-y: scroll;
  }
}
