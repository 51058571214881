.contact__section__one {
  padding-top: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222222;
  color: white;
  min-height: 40vh;
}
.contact__section__one > h1 {
  font-size: 35px;
}
.contact__section__two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5% 0;
}
.contact__section__two > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}
.contact__section__two > p {
  font-size: 14px;
  line-height: 25px;
  margin: 10px 0;
}
.contact__section__two > span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.contact__section__two > span > p:first-of-type {
  color: #ffcc00;
}
.contact__section__three {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5% 0;
  background: #ebe8e5;
}
.contact__section__three > h1 {
  font-size: 25px;
  margin-bottom: 25px;
}
.contact__section__three > button {
  border: 0;
  padding: 10px 35px;
  background: #ffcc00;
  color: #fff;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  .contact__section__one {
    padding-top: 25vh;
    justify-content: start;
    padding-left: 5%;
  }
  .contact__section__two {
    justify-content: center;
    align-items: start;
    padding: 5% 5%;
  }
  .contact__section__three {
    align-items: start;
    justify-content: center;
    padding: 5% 5%;
  }
}
