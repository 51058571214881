.home {
  padding-top: 20vh;
}
.home__section__one {
  padding: 2.5% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__section__one__start {
  width: 50%;
  height: 100%;
}
.home__section__one__start > h3 {
  font-weight: 400;
  font-size: 16px;
}
.home__section__one__start > h1 {
  font-size: 60px;
  text-wrap: wrap;
}
.home__section__one__start > span {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #cc66cc;
  font-size: 17px;
  margin-top: 10px;
  cursor: pointer;
}
.home__section__one__end {
  width: 50%;
  /* background: url("../../assets/robert-gareth-iC-kqcm_feE-unsplash.jpg") center; */
  background-size: cover;
  height: 500px;
  display: flex;
}
.home__section__one__end__span {
  margin-top: auto;
  display: flex;
  width: 100%;
}
.home__section__one__end__span > div {
  background: #222222;
  color: white;
  width: 300px;
  padding: 20px 20px;
}
.home__section__one__end__span > span {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 40px;
}
.home__section__one__end__span__button {
  font-size: 20px;
  padding: 5px 5px;
  background: white;
  color: #222222;
  border: 0;
  cursor: pointer;
}
.home__section__two {
  padding: 2.5% 10%;
  background: #ebe8e5;
}
.home__section__two > h1 {
  font-size: 30px;
  margin-bottom: 20px;
}
.home__section__two__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.home__section__two__block {
  width: 24%;
  color: white;
  padding: 20px 20px;
  background: #222;
}
.home__section__two__block > h1 {
  margin-bottom: 20px;
}
.home__section__three {
  display: flex;
  justify-content: space-between;
}
.home__section__three__start {
  width: 48%;
  padding: 30px 0;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home__section__three__start > span {
  font-size: 40px;
  margin-bottom: 10px;
}
.home__section__three__start > p {
  font-size: 14px;
  line-height: 25px;
  margin: 10px 0;
}
.home__section__three__start > div {
  margin-top: 10px;
}
.home__section__three__start > div > h3 {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
}
.home__section__three__start > div > h2 {
  color: #ffcc00;
}
.home__section__three__end {
  width: 50%;
  background: url("../../assets/girl-studying-university-library.webp") center
    no-repeat;
  background-size: contain;
}
.home__section__four {
  background: #222222;
  color: white;
  padding: 2.5% 10%;
}
.home__section__four > h1 {
  font-size: 50px;
  margin-bottom: 30px;
}
.home__section__four__wrap {
  display: flex;
  align-items: start;
  gap: 20px;
  width: 100%;
}
.home__section__four__wrap p {
  font-size: 13px;
  line-height: 25px;
}
.home__section__four__block1 {
  width: 50%;
}
.home__section__four__block1 > img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.home__section__four__block2 {
  width: 30%;
}
.home__section__four__block2 > img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.home__section__four__block3 {
  width: 20%;
}
.home__section__four__block3 > img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.home__section__five {
  min-height: 60vh;
  padding: 2.5% 10%;
}
.home__section__five__start {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.home__section__five__start__header {
  width: 70%;
}
.home__section__five__start__header > h1 {
  width: 60%;
  font-size: 50px;
}
.home__section__five__start__wrap {
  width: 30%;
}
.home__section__five__start__wrap > p {
  font-weight: 200;
  font-size: 13px;
  line-height: 25px;
  margin-bottom: 10px;
}
.home__section__five__start__wrap > h3 {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
}
.home__section__five__start__wrap > h3:hover {
  color: #ffcc00;
}
.home__section__five__end {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 50px;
}
.home__section__five__end__start {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.home__section__five__end__block {
  display: flex;
  align-items: start;
  padding: 20px 20px;
  width: 50%;
}
.home__section__five__end__block > div > h1 {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
}
.home__section__five__end__block > div > p {
  font-weight: 100;
  font-size: 13px;
  line-height: 25px;
  margin-bottom: 10px;
}
.home__section__five__end__block > div > h3 {
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
}
.home__section__five__end__block__icon {
  width: 100px;
  color: #ffcc00;
}
.home__section__five__end__img {
  width: 30%;
  height: 600px;
  object-fit: cover;
}
@media all and (max-width: 768px) {
  .home {
    padding: 5% 5%;
    padding-top: 10vh;
  }
  .home__section__one {
    padding: 50px 0;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .home__section__one__start {
    width: 100%;
    height: 100%;
  }
  .home__section__one__start > h1 {
    font-size: 45px;
  }
  .home__section__one__end {
    margin-top: 20px;
    width: 100%;
    height: 400px;
    display: flex;
  }
  .home__section__two {
    padding: 5% 5%;
  }
  .home__section__two__wrap {
    justify-content: start;
  }
  .home__section__two__block {
    width: 100%;
    margin-bottom: 10px;
  }
  .home__section__three {
    flex-direction: column;
  }
  .home__section__three__start {
    width: 100%;
    padding-left: 5%;
  }
  .home__section__three__end {
    object-fit: cover;
    width: 100%;
    height: 50vh;
  }
  .home__section__four {
    padding: 5% 5%;
  }
  .home__section__four > h1 {
    font-size: 30px;
  }
  .home__section__four__wrap {
    flex-direction: column;
    align-items: start;
  }
  .home__section__four__block1 {
    width: 100%;
  }
  .home__section__four__block2 {
    width: 100%;
  }
  .home__section__four__block3 {
    width: 100%;
  }
  .home__section__five {
    padding: 5% 5%;
  }
  .home__section__five__start {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .home__section__five__start__header {
    width: 100%;
  }
  .home__section__five__start__header > h1 {
    width: 100%;
    font-size: 30px;
  }
  .home__section__five__start__wrap {
    width: 100%;
    margin-top: 30px;
  }
  .home__section__five__end {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .home__section__five__end__start {
    width: 100%;
    flex-wrap: wrap;
    justify-content: start;
  }
  .home__section__five__end__block {
    padding: 20px 0;
    width: 100%;
  }
  .home__section__five__end__img {
    width: 100%;
    height: auto;
  }
}
