.courses {
  min-height: 100vh;
}
.courses__section__one {
  padding: 2.5% 25%;
  padding-top: 25vh;
}
.courses__section__one > h3 {
  font-size: 13px;
  font-weight: 100;
  color: #cc66cc;
}
.courses__section__one > h1 {
  font-size: 60px;
}
.courses__section__two {
  padding: 0 25%;
}
.courses__section__two > h3 {
  font-size: 35px;
  font-weight: 100;
  margin-bottom: 20px;
}
.courses__section__two > p {
  font-size: 15px;
  line-height: 35px;
  margin-bottom: 20px;
}
.courses__section__two > img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.courses__section__three {
  padding: 2.5% 25%;
}
.courses__section__three > p {
  font-size: 15px;
  line-height: 35px;
  margin-bottom: 20px;
}
.courses__section__three__block {
  margin: 20px 0;
}
.courses__section__three__block > h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffcc00;
}
.courses__section__three__block > h2 {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 10px;
}
.courses__section__three__block > h3 {
  font-size: 12px;
  font-weight: 100;
  line-height: 25px;
  margin-bottom: 10px;
}
.courses__section__three__block > h4 {
  font-size: 14px;
  font-weight: 100;
  line-height: 25px;
  color: #13182c;
}
.courses__section__three__block__list1 {
  margin-bottom: 20px;
}
.courses__section__three__block__list1 > h3 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #888;
}
.courses__section__three__block__list1 > ul > li {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 15px 0;
  font-size: 14px;
  font-weight: 200;
}
.courses__section__three__block__list2 {
  margin-bottom: 20px;
}
.courses__section__three__block__list2 > h3 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #888;
}
.courses__section__three__block__list2 > ul > li {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 15px 0;
  font-size: 14px;
  font-weight: 200;
}

@media all and (max-width: 768px) {
  .courses__section__one {
    padding: 5% 5%;
    padding-top: 15vh;
  }
  .courses__section__one > h1 {
    font-size: 30px;
  }
  .courses__section__two {
    padding: 0 5%;
  }
  .courses__section__two > h3 {
    font-size: 25px;
    font-weight: 100;
    margin-bottom: 20px;
  }
  .courses__section__three {
    padding: 0 5%;
  }
}
