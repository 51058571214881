.countdown {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}

@media all and (max-width: 768px) {
  .countdown {
    text-align: start;
    margin-top: 20px;
  }
}
