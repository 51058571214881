.apply__section__one {
  padding: 2.5% 10%;
  padding-top: 20vh;
  min-height: 60vh;
  background: url("../../assets/mikael-kristenson-3aVlWP-7bg8-unsplash.jpg");
  background-size: cover;
  display: flex;
  justify-content: space-between;
  color: white;
}
.apply__section__one__start {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.apply__section__one__start > h1 {
  font-size: 40px;
  margin: 0;
}
.apply__section__one__start > p {
  font-size: 14px;
  line-height: 25px;
  margin-top: 30px;
}
.apply__section__one__end {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.apply__section__one__end__button__apply {
  width: 300px;
  padding: 10px 10px;
  margin: 5px 0;
  background: #ffcc00;
  color: white;
  border: 0;
  cursor: pointer;
}
.apply__section__one__end__button__brochures {
  width: 300px;
  padding: 10px 10px;
  margin: 5px 0;
  background: transparent;
  color: white;
  border: 2px solid white;
  cursor: pointer;
}
.apply__section__two {
  padding: 2.5% 10%;
  background: #f3f3f3;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.apply__section__two > h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 20px;
}
.apply__section__two__wrap {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.apply__section__two__block {
  width: 22.5%;
  min-height: 250px;
  padding: 20px 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
}
.apply__section__two__block > p {
  font-weight: 200;
  font-size: 14px;
  line-height: 25px;
  margin-top: 30px;
  color: #666;
}
.apply__section__two__block > h1 {
  font-weight: 100;
  font-size: 20px;
  line-height: 30px;
  color: #ffcc00;
}
.apply__section__three {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5% 10%;
  background: white;
  color: #222222;
  min-height: 40vh;
}
.apply__section__three > h1 {
  font-size: 40px;
  margin-bottom: 20px;
  color: crimson;
}
.modal__apply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  color: #222222;
}
.modal__apply__logo {
  object-fit: contain;
  height: 50px;
  margin-bottom: 35px;
}
.modal__apply__content {
  text-align: center;
  width: 500px;
}
.modal__apply__content__input__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.modal__apply__content__input__wrap > span {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 49%;
  margin-bottom: 10px;
}
.modal__apply__content__input__wrap > span > input {
  padding: 10px 15px;
  color: #222222;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
}
.modal__apply__content > h1 {
  font-size: 22.5px;
  font-weight: 500;
  margin-bottom: 0;
  color: #444;
  margin-bottom: 5px;
}
.modal__apply__content > p {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #666;
}
.modal__apply__buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}
.modal__apply__button {
  padding: 12.5px 25px;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: white;
  background: #cc66cc;
  cursor: pointer;
}
.modal__apply__close {
  padding: 12.5px 25px;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #222222;
  background: white;
  border: 1px solid #222222;
  cursor: pointer;
}
.modal__apply__label {
  font-size: 13px;
  font-weight: 500;
  color: #777;
  margin-bottom: 10px;
}
.modal__apply__input {
  padding: 10px 15px;
  color: #222222;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
  min-height: 30px;
}
.image__upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px dashed #1475cf;
  min-height: 30px;
  cursor: pointer;
  border-radius: 5px;
  gap: 10px;
  padding: 5px 5px;
  width: 100%;
}
.image__upload__label {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 11px;
  color: var(--tertiary-text-color);
  background: #e9f0ff;
  padding: 5px;
}
.image__upload__label > span {
  margin-left: auto;
}
.modal__apply__message {
  padding: 20px;
  color: #222222;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  background-color: lightgreen;
  width: 500px;
  text-align: center;
}
.modal__apply__message.incomplete {
  background-color: #ff4d4d77;
  color: crimson;
  padding: 10px 20px;
}
@media all and (max-width: 768px) {
  .apply__section__one {
    padding: 5% 5%;
    flex-direction: column;
    padding-top: 15vh;
  }
  .apply__section__one__start {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .apply__section__one__end {
    width: 100%;
    justify-content: center;
    align-items: start;
    margin-top: 30px;
  }
  .apply__section__one__end__button__apply {
    width: 100%;
  }
  .apply__section__one__end__button__brochures {
    width: 100%;
  }
  .apply__section__two {
    padding: 5% 5%;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .apply__section__two__wrap {
    flex-direction: column;
  }

  .apply__section__two__block {
    width: 100%;
    margin-bottom: 10px;
  }
  .apply__section__three {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 10% 5%;
    min-height: auto;
  }
  .apply__section__three > h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .modal__apply__content {
    width: 100%;
  }
  .modal__apply__content__input__wrap > span {
    width: 100%;
  }
  .modal__apply__message {
    width: 100%;
  }
}
